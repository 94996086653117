<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :btnFlag="btnFlag"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :rules="rules"
    >
      <div class="search" slot="subSlot">
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="publicfuc.jumpToPage('createGoods', {}, $router)"
          size="small"
          >新增</el-button
        >
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/massesPersonnel",
      searchVal: "",
      bntLoading: false,
      btnFlag: { addShow: false, delShow: false },
      searchQuery: { siteName: "", siteCode: "" },
      rules: {
        //传递给APPlist的表单验证
      },
      props: [
        {
          label: "昵称",
          prop: "name",
          align: "center",
        },

        {
          label: "手机",
          prop: "phone",
          align: "center",
        },
        {
          label: "创建时间",
          prop: "createTime",
          align: "center",
        },

        // {
        //   label: "状态",
        //   prop: "isPutaway",
        //   align: "center",
        //   type: "html",
        //   formatter: function (row) {
        //     const map = [
        //       { color: "#409EFF", text: "在售" },
        //       { color: "#666", text: "下架" },
        //     ];
        //     return (
        //       "<span style='color:" +
        //       map[row.isPutaway].color +
        //       "'>" +
        //       map[row.isPutaway].text +
        //       "</span>"
        //     );
        //   },
        // },

        {
          label: "操作",
          prop: "qrcode",
          align: "center",
          width: 250,
          type: "operationLink",
          formatter: function (row) {
            const buttonList = [
              {
                text: "冻结",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-delete",
                type: "danger",
                handler: function () {},
              },
            ];
            return buttonList;
          },
        },
      ],
      formProps: [],
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.changeCon p {
  color: #999;
}
.changeCon b {
  margin: 0 10px;
  color: #66b1ff;
}
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
